<template>
  <section
    :class="[
      blok.enable_header
        ? 'relative mx-auto max-w-[var(--breakpoint-xl)] px-4 sm:px-8'
        : 'mx-auto max-w-prose lg:max-w-none',
    ]"
  >
    <div v-if="blok.enable_header" class="mb-8 max-w-prose">
      <div class="text-base font-bold text-yellow-400 uppercase">
        {{ blok.subheading || 'Blog' }}
      </div>
      <h2
        v-if="blok.headline"
        class="mt-0.5 text-3xl font-extrabold text-white"
      >
        {{ blok.headline }}
      </h2>
      <p v-if="blok.lead" class="mt-1 text-lg font-medium text-neutral-400">
        {{ blok.lead }}
      </p>
    </div>
    <div
      v-if="!loading && !isLoading && blog?.length === 0"
      class="text-center text-neutral-400"
    >
      There are currently no blog posts available.
    </div>
    <div class="grid gap-6 sm:grid-cols-2 md:grid-cols-3">
      <LazyMarketingCardBlog
        v-for="(article, index) in blog"
        v-if="!loading && !isLoading && blog?.length > 0"
        :key="index"
        :article="article"
      />
      <LazyMarketingCardBlogLoading
        v-for="i in perPage"
        v-if="loading || isLoading"
        :key="i"
      />
      <UCard
        v-if="totalItems > perPage"
        :ui="{
          root: 'bg-neutral-900/40 backdrop-blur-lg col-span-full mx-auto mt-4',
          body: 'p-1 sm:p-1',
        }"
      >
        <UPagination
          v-model:page="currentPage"
          :total="totalItems"
          :items-per-page="perPage"
          :default-page="1"
          :show-edges="true"
          :sibling-count="1"
          @update:page="handlePageChange"
        />
      </UCard>
    </div>
  </section>
</template>

<script setup lang="ts">
const route = useRoute();
const { fetchBlog, totalItems, isLoading } = useStoryblokClient();
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
  uuid: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
});

const blog = ref([]);
const currentPage = ref(1);
const perPage = ref(6);
const loading = ref(true);

// Load blog and set them in the reactive `blog` array
const loadBlog = async (
  page: {
    type: number;
    default: 0;
  },
  perPage: number
) => {
  loading.value = true;
  try {
    const [stories] = await Promise.all([fetchBlog(page, perPage)]);
    blog.value = stories;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

// Handle page change
const handlePageChange = async (page: number) => {
  await loadBlog(page, perPage.value);
  if (route.path.includes('/blog')) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

// Initial load
onMounted(async () => {
  if (props.blok.enable_header) {
    perPage.value = 3;
  }
  await loadBlog(currentPage.value, perPage.value);
});
</script>
